import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getHttpParamsWithEnabledRequestCaching } from '@dworkflow/shared/interceptors/global-http-interceptor';
import { TenantService } from '@dworkflow/shared/services/tenant.service';
import { guid } from '@dworkflow/shared/utility/guid';
import * as engineActions from '@dworkflow/state/engine.actions';
import { Store } from '@ngrx/store';
import lodash from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BenachrichtigungenModel } from '../model/benachrichtigungen.model';
import { BenachrichtigungsModel } from '../model/benachrichtigungs.model';
import { EditWorkflowInformationenModel } from '../model/edit-workflowinformationen.model';
import { EditWorkflowverlaufModel } from '../model/edit-workflowverlauf.model';
import { EngineBenachrichtigungsTyp } from '../model/engine-benachrichtigungs.typ.enum';
import { EngineEinstellungenModel } from '../model/engine-einstellungen.model';
import { ErstelleWorkflowModel } from '../model/erstelle-workflow.model';
import { HaftnotizModel } from '../model/haftnotiz.model';
import { WorkflowInformationenFuerTabelleModel } from '../model/workflow-informationen-fuer-tabelle.model';
import { WorkflowProtokollModel } from '../model/workflow-protokoll.model';
import { WorkflowModel } from '../model/workflow.model';
import { WorkflowverlaufModel } from '../model/workflowverlauf.model';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  constructor(
    private http: HttpClient,
    private store: Store,
    private tenantService: TenantService
  ) {}

  public speichereHaftnotiz(workflowId: guid, haftnotiz: string): Observable<HaftnotizModel> {
    return this.http.post<HaftnotizModel>(
      `${environment.engine.workflowEndpoint}/${workflowId}/haftnotiz`,
      {
        haftnotiz,
      }
    );
  }

  public speichereWorkflowInformationen(
    workflowInformationen: EditWorkflowInformationenModel
  ): Observable<WorkflowModel> {
    return this.http.put<WorkflowModel>(
      `${environment.engine.workflowEndpoint}/${workflowInformationen.workflowId}/informationen`,
      workflowInformationen
    );
  }

  public erstelleWorkflow(newWorkflow: ErstelleWorkflowModel): Observable<WorkflowModel> {
    // workflow ist immutable, also wird er hier geklont
    const newWorkflowClone = lodash.cloneDeep(newWorkflow);
    return this.http.post<WorkflowModel>(environment.engine.workflowEndpoint, newWorkflowClone);
  }

  public getWorkflow(workflowId: guid, timestamp: string): Observable<WorkflowModel> {
    let params = new HttpParams();
    if (timestamp) {
      params = params.set('timestamp', timestamp);
    }
    return this.http.get<WorkflowModel>(`${environment.engine.workflowEndpoint}/${workflowId}`, {
      params,
    });
  }

  public getWorkflowInformationenFuerTabelle(
    workflowIds: guid[]
  ): Observable<WorkflowInformationenFuerTabelleModel[]> {
    return this.http.post<WorkflowInformationenFuerTabelleModel[]>(
      `${environment.engine.workflowEndpoint}/get-reduziert`,
      workflowIds
    );
  }

  public getAlleWorkflows(
    timestampTicks: string
  ): Observable<WorkflowInformationenFuerTabelleModel[]> {
    let params = new HttpParams();
    if (timestampTicks) {
      params = params.set('timestamp', timestampTicks);
    }
    return this.http.get<WorkflowInformationenFuerTabelleModel[]>(
      `${environment.engine.workflowEndpoint}/alle`,
      { params }
    );
  }

  public getAlleWorkflowsAnzahl(): Observable<number> {
    return this.http.get<number>(`${environment.engine.workflowEndpoint}/alle/anzahl`);
  }

  // Holt die Workflows für den angegebenen Principal. Wenn nicht angegeben, wird das Backend
  // den aktuellen Principal verwenden.
  public getWorkflowsByInitiator(
    timestampTicks: string,
    anzahlWorkflows?: number,
    principalId?: number
  ): Observable<WorkflowInformationenFuerTabelleModel[]> {
    let params = new HttpParams();
    if (timestampTicks) {
      params = params.set('timestamp', timestampTicks);
    }

    if (principalId) {
      params = params.set('principalId', principalId.toString());
    }
    if (anzahlWorkflows && !isNaN(anzahlWorkflows)) {
      params = params.set('count', anzahlWorkflows);
    }

    return this.http.get<WorkflowInformationenFuerTabelleModel[]>(
      `${environment.engine.workflowEndpoint}/by-initiator-or-besitzer`,
      {
        params,
      }
    );
  }

  public getWorkflowsByPrincipalId(
    timestampTicks: string,
    principalId: number,
    anzahlWorkflows?: number
  ): Observable<WorkflowInformationenFuerTabelleModel[]> {
    let params = new HttpParams();
    if (timestampTicks) {
      params = params.set('timestamp', timestampTicks);
    }

    if (anzahlWorkflows && !isNaN(anzahlWorkflows)) {
      params = params.set('count', anzahlWorkflows);
    }

    return this.http.get<WorkflowInformationenFuerTabelleModel[]>(
      `${environment.engine.workflowEndpoint}/vertretung/${principalId}`,
      {
        params,
      }
    );
  }

  public starteWorkflowverlauf(workflowverlaufId: guid, workflowId: guid): Observable<void> {
    const data = {
      WorkflowId: workflowId,
    };
    return this.http.post<void>(
      `${environment.engine.workflowverlaufEndpoint}/${workflowverlaufId}/start`,
      data
    );
  }

  public editWorkflowverlauf(
    workflowverlaufId: guid,
    workflowverlauf: EditWorkflowverlaufModel
  ): Observable<WorkflowverlaufModel> {
    return this.http.post<WorkflowverlaufModel>(
      `${environment.engine.workflowverlaufEndpoint}/${workflowverlaufId}`,
      workflowverlauf
    );
  }

  public storniereWorkflow(workflowId: guid, kommentar: string): Observable<unknown> {
    return this.http.post(`${environment.engine.workflowEndpoint}/${workflowId}/stornieren`, {
      kommentar,
    });
  }

  public loescheWorkflow(workflowId: guid): Observable<unknown> {
    return this.http.delete(`${environment.engine.workflowEndpoint}/${workflowId}`);
  }

  public loescheHaftnotiz(workflowId: guid): Observable<unknown> {
    return this.http.delete(environment.engine.workflowEndpoint + '/' + workflowId + '/haftnotiz');
  }

  public getEinstellungen(): Observable<EngineEinstellungenModel> {
    return this.http.get<EngineEinstellungenModel>(environment.engine.einstellungenEndpoint);
  }

  public getBenachrichtigungen(): Observable<BenachrichtigungenModel> {
    return this.http.get<BenachrichtigungenModel>(
      environment.engine.einstellungenEndpoint + '/benachrichtigungen'
    );
  }

  public updateEinstellungen(
    einstellungen: EngineEinstellungenModel
  ): Observable<EngineEinstellungenModel> {
    return this.http.put<EngineEinstellungenModel>(
      environment.engine.einstellungenEndpoint,
      einstellungen
    );
  }

  public updateBenachrichtigung(
    typ: EngineBenachrichtigungsTyp,
    betreff: string,
    inhalt: string,
    versandAktiviert: boolean
  ): Observable<BenachrichtigungsModel> {
    return this.http.post<BenachrichtigungsModel>(
      environment.engine.einstellungenEndpoint + '/benachrichtigungen',
      {
        typ,
        betreff,
        inhalt,
        versandAktiviert,
      }
    );
  }

  public getScribanVorschau(template: string, daten: unknown): Observable<string> {
    return this.http
      .post<string>(
        `${environment.engine.einstellungenEndpoint}/template-vorschau`,
        {
          template,
          renderData: JSON.stringify(daten),
        },
        {
          responseType: 'text' as 'json',
        }
      )
      .pipe(
        catchError(e =>
          of(
            htmlEncode((e as HttpErrorResponse)?.error as string)
              ?.split(/[\r\n]/)
              ?.find(line => line?.trim()?.startsWith('Message:'))
              ?.replace('Message:', 'Error:')
          )
        )
      );
  }

  public getDefaultTexte(): Observable<string> {
    return this.http.get<string>(environment.engine.einstellungenEndpoint + '/texte/default', {
      responseType: 'text' as 'json',
    });
  }

  public getCombinedTexte(): Observable<string> {
    let params = getHttpParamsWithEnabledRequestCaching();
    // TenantUrl als Param setzen, um chaching auf den aktuellen Tenant zu begrenzen
    params = params.set('tenant', this.tenantService.currentTenant?.id ?? 0);
    return this.http
      .get<string>(environment.engine.einstellungenEndpoint + '/texte/combined', {
        params: params,
        responseType: 'text' as 'json',
      })
      .pipe(retry({ count: 5, resetOnSuccess: true, delay: 1000 }));
  }

  public getCombinedTexteWithoutCache(): Observable<string> {
    return this.http
      .get<string>(environment.engine.einstellungenEndpoint + '/texte/combinedWithoutCache', {
        params: getHttpParamsWithEnabledRequestCaching(),
        responseType: 'text' as 'json',
      })
      .pipe(retry({ count: 5, resetOnSuccess: true, delay: 1000 }));
  }

  public postCustomTexte(texteJson: string): Observable<string> {
    return this.http.post<string>(
      environment.engine.einstellungenEndpoint + '/texte/custom',
      { CustomTexte: texteJson },
      { responseType: 'text' as 'json' }
    );
  }

  public updateStartseitenBeschreibung(beschreibung: string): Observable<string> {
    return this.http.put<string>(
      environment.engine.einstellungenEndpoint + '/startseitenbeschreibung',
      {
        StartseitenBeschreibung: beschreibung,
      },
      { responseType: 'text' as 'json' }
    );
  }

  public loadMeineWorkflows(): void {
    this.store.dispatch(engineActions.loadMeineWorkflows());
  }

  public loadMeineWorkflowsFuerStartseite(): void {
    this.store.dispatch(engineActions.loadMeineWorkflowsFuerStartseite());
  }

  public loadAlleWorkflows(): void {
    this.store.dispatch(engineActions.loadAlleWorkflows());
  }

  public loadWorkflowProtokoll(workflowId: guid): void {
    this.store.dispatch(engineActions.loadProtokoll({ workflowId: workflowId }));
  }

  public getProtokoll(workflowId: guid): Observable<WorkflowProtokollModel> {
    return this.http
      .get<WorkflowProtokollModel>(
        `${environment.engine.protokollEndpoint}/${workflowId}/protokoll`
      )
      .pipe(map(p => <WorkflowProtokollModel>{ ...p, workflowId }));
  }
}

function htmlEncode(s: string): string {
  return s
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&#39;')
    .replace(/"/g, '&#34;');
}
